<template>
  <div class="layout index-layout">
    <div class="content-wrapper">
      <slot />
    </div>
  </div>
</template>
<style lang="scss">
  @import '@/assets/styles/common.scss';

  .index-layout {
    padding: 0 rem(16) rem(50);

    @include desktop-up {
      padding: 0 0 rem(90);
    }

    .heading,
    .block-title {
      text-align: center;
    }

    .static-page .heading {
      width: 100%;
      margin: 0;

      h2 {
        margin: rem(8) 0 rem(32);

        @include desktop-up {
          margin: rem(8) 0 rem(56);
        }
      }

      h6 {
        color: $secondary;
      }
    }
  }
</style>
